$mobile-width: 62rem;

$navbar-color: #111; //rgb(72, 115, 166); //rgb(31, 38, 45);
$navbar-hover-color: rgb(39, 169, 227);
$navbar-text: rgba(255, 255, 255, 0.8);

@mixin box_shadow() {
  box-shadow: 1px 1px 15px -5px rgba(0, 0, 0, 0.5);
  background-color: white;
  //padding: 5px 10px;
}

.page-nav {
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  height: 56px; // has match height with page-nav
  box-shadow: 1px 1px 25px -10px rgb(0 0 0 / 10%);
  background-color: white;

  display: flex;
  align-items: center;
  padding-right: 1em;
  .page-nav-logo {
    display: flex !important;
    align-items: center;
    min-width: 215px;
  }

  .page-nav-button {
    font-size: 1.4em;
    color: #1a1818;
    cursor: pointer;
    width: 50px;
    text-align: center;
  }
  .page-nav-menu {
    display: flex;
    align-items: center;
    padding: 0 1em;
    height: 56px;
    cursor: pointer;

    &:hover {
      border-bottom: 3px solid #1a1818;
    }

    .title {
      font-size: 16px;
      white-space: nowrap;
    }
    &.page-nav-menu-selected {
      border-bottom: 3px solid #1a1818;
    }
  }
  .page-nav-menu-selected {
    border-bottom: 3px solid #1a1818;
  }
  .page-nav-user {
    display: flex;
    align-items: center;
    .people-circle {
      margin: 0px;
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .responsive_desktop {
    display: none !important;
  }
  .responsive_mobile {
  }
  .responsive_mobile_force {
    display: block !important;
  }
}

@media screen and (min-width: $mobile-width) {
  .responsive_desktop {
  }
  .responsive_mobile {
    display: none !important;
  }
}

.switchto-overlay {
  width: 200px;
  .mat-menu-content {
    padding-bottom: 0px !important;
  }
  .title {
    padding: 10px;
    font-weight: 500;
  }
  .app {
    display: flex;
    align-items: center;
    img {
      height: 2em;
    }
  }
  .adminConsole {
    display: flex;
    align-items: center;
    background: #f26822;
    color: white;
    &:hover {
      background: #f26822;
    }
  }
}

.user-dropDown {
  margin-top: 10px;
  .nav-user {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin: 10px;
  }

  .nav-acc {
    font-size: smaller;
    color: #707070;
    border-bottom: 1px solid #eee;
    max-width: 200px;
    padding: 0 10px 10px 10px;
  }

  .people-circle {
    cursor: auto !important;
  }
}

mat-sidenav-container.page-container {
  position: absolute;
  top: 57px; // has match height with page-nav
  bottom: 0px;
  left: 0;
  right: 0;
  //   mat-sidenav {
  //      min-width:360px;
  //   }
  .ctlMainMenu {
    position: absolute;
    left: 20px;
    top: 150px;
    display: block;
    float: left;
    width: 300px;
    min-height: 200px;
    border: 1px solid #eee;
    border-radius: 10px;
    padding: 10px;
    background: white;
    @include box_shadow();
    z-index: 10000;

    &:after,
    &:before {
      right: 100%;
      top: 25px;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-color: rgba(136, 183, 213, 0);
      border-right-color: #fff;
      border-width: 15px;
      margin-top: -15px;
    }
    &:before {
      border-color: rgba(194, 225, 245, 0);
      border-right-color: #f0f0f0;
      border-width: 18px;
      margin-top: -18px;
    }

    display: flex;
    flex-wrap: wrap;
    .cell {
      text-align: center;

      flex: 0 0 90px;
      height: 90px;
      margin-right: 10px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 5px;
      cursor: pointer;
      background: #1a1818;
      color: white;
      &:hover {
        background-color: #707070;
      }
      .title {
        font-weight: 300;
        margin-top: 8px;
      }
    }
  }
}

.page-sidenav {
  //background-color: $navbar-color;
  color: $navbar-text;
  //box-shadow: 1px 0px 1px 0px rgba(100, 100, 100, 0.3);
  width: 215px;
  @include box_shadow();

  .page-sidenav-bottom {
    padding: 15px 4px;
    width: 40px;
    text-align: center;
    font-size: 1em;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    right: 0;
    transition: transform 0.3s ease;
    &:hover {
      transform: translateX(-3px);
    }
  }

  .page-sidenav-collapse {
    text-align: center;
    font-size: 0.6em;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background: #707070;
    padding: 10px 7px 10px 3px;
    cursor: pointer;
    position: absolute;
    bottom: 50%;
    right: 0px;
    transition: transform 0.3s ease;
    opacity: 40%;
    &:hover {
      transform: translateX(-2px);
      opacity: 1;
    }
  }
  .page-sidenav-expand {
    text-align: center;
    font-size: 0.6em;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background: #707070;
    padding: 10px 3px 10px 7px;
    cursor: pointer;
    position: absolute;
    bottom: 50%;
    left: -4px;
    transition: transform 0.3s ease;
    opacity: 40%;
    &:hover {
      transform: translateX(2px);
      opacity: 1;
    }
  }

  .page-sidenav-header {
    padding: 8px 16px 8px 16px;
    // height: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: white;
    color: #1a1818;
    font-weight: 500;
    position: relative;
    z-index: 10;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
    height: 34px;
  }

  .page-sidenav-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: white;
    overflow: hidden;
  }
  .page-sidenav-body {
    flex: 1 1 auto;
    overflow: auto;
    background: #282525;
  }
  .page-sidenav-menu {
    cursor: pointer;
    flex: 1 1 auto;
    hr {
      width: 100% !important;
    }
    .menu-icon {
      height: 44px;
      width: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .menu-text {
      height: 44px;
      display: flex;
      align-items: center;
      &.page-sidenav-menu-selected {
        background-color: lighten($navbar-color, 5);
        //border-left: 3px solid white;
        .menu-icon {
          border-left: 3px solid white;
        }
      }
      &.page-sidenav-submenu-selected {
        background-color: lighten($navbar-color, 5);
      }
      .expand-icon {
        text-align: right;
        padding-right: 10px;
      }
      .badge {
        position: relative;
        color: white;
        div {
          padding: 0px 5px;
          background-color: #666;
          top: 0;
          left: 0;
          min-width: 2em;
          line-height: 1.5em;
          border-radius: 50px;
          text-align: center;
          margin-right: 5px;
        }
      }
    }
    .sub-menu-text {
      height: 44px;
      display: flex;
      align-items: center;
      padding-left: 44px;
      border-radius: 0;
      margin: 0;
      &.page-sidenav-menu-selected {
        background-color: lighten($navbar-color, 5);
        border-left: 3px solid white;
        padding-left: 41px;
      }
    }
  }

  .page-sidenav-menu-old {
    padding: 16px 16px;
    font-size: 1em;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 300;
    > fa-icon {
      flex: 0 0 2em;
    }
    > div {
      margin-left: 5px;
    }
    &:hover {
      //background-color: lighten($navbar-color, 5);
      border-left: 3px solid white;
    }
  }

  .page-sidenav-mainmenu {
    background-color: white;
    font-size: 16px;
    font-weight: 500;
    color: #1ca0da;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
    &:hover {
      background-color: #1ca0da;
      color: white;
    }

    display: flex;
    align-items: center;
    .icon {
      text-align: left;
      width: 2em;
    }
  }
}
.page-sidenav-closed {
  width: 50px;
}
